<template>
  <div class="problemList">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left" v-loading="loading">
          <p><span>发起新帖</span><span>#话题</span></p>
          <form-create @done="loading=false;" v-model="ruleForm" ref="ruleForm" label-width="0px" size="medium" class="demo-ruleForm">
            <el-form-item label="" prop="title" :rules="[{ required: true, message: '请填写标题', trigger: 'blur' }]">
              <el-input v-model="ruleForm.title" placeholder="请填写标题"></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="11">
                <el-form-item label="" prop="type" :rules="[{ required: true, message: '请选择发布区域', trigger: 'blur' }]">
                  <div style="padding: 2px 0;">
                    <el-select style="width: 100%;" size="medium" v-model="ruleForm.type" placeholder="请选择发布区域">
                      <el-option v-for="item in typeCourse" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="1">
                <el-form-item label="" prop="tags" :rules="[{ required: true, message: '请输入标签', trigger: 'blur' }]">
                  <div class="tag_box" style="border: 1px solid #dcdfe6;background-color: #fff;padding: 0 10px;top: 2px;position: relative;">
                    <diy-form-item :item-type="0" size="mini" type="tag" prop="tags" v-model="ruleForm.tags" placeholder="请输入标签" :add-botton="'+ 标签'">
                    </diy-form-item>
                  </div>
                </el-form-item>

              </el-col>
            </el-row>
            <diy-form-item label="" prop="content"
                           :item-type="1"
                           type="editor"
                           v-model="ruleForm.content"
                           :rules="[{ required: true, message: '请输入内容', trigger: 'blur' }]"
                           :editorConfig="{module:'default',maximumWords:10000,initialFrameHeight:280}"
            ></diy-form-item>
            <div class="btn">
              <div @click="submitForm('ruleForm')">
                {{ id ? "保存" : "发表" }}
              </div>
            </div>
          </form-create>
        </div>
        <div class="content-right">
          <Recommend class="border"></Recommend>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { typeList, submit, edit, forumDetail } from "@/api/forum.js"
import Recommend from "./components/Recommend"
export default {
  components: { Recommend },
  data () {
    return {
      loading:false,
      ruleForm: {
        title: "",
        tags: [],
        type: "",
        content: "",
      },
      // 发布区域
      typeCourse: "",

      recommendList: []
    }
  },
  created () {
    this.id = this.$route.query.id ? this.$route.query.id : ""
    this.action = this.$store.state.action
    // 帖子分类
    typeList({})
      .then(({ data }) => {
        console.log(data)
        this.typeCourse = [...data]
      })
      .catch((err) => {
        console.log(err, "论坛分类失败")
      })
  },
  mounted () {
    // 查看帖子详情
    if (this.id) {
      this.getForumDetails()
    }
  },
  methods: {
    // 获取详情
    getForumDetails () {
      forumDetail({ id: this.id })
        .then(({ data }) => {
          let {title,tags,tagsStr,type,content} = (data || {}).info || {};
          this.ruleForm = {title,tags:tagsStr?tagsStr.split(','):[],type,content,}
        })
        .catch((err) => {
          console.log(err, "详情")
        })
    },
    // 编辑发表
    submitForm (formName) {
      this.$refs[formName].validate((form,valid,errors) => {
        console.log("submitForm" , {...this.ruleForm});
        if (valid) {
          if (this.id) {
            edit({...this.ruleForm,id:this.id}).then((res) => {
                this.ruleForm = {title: "",tags: [],type: "",content: "",}
                this.$message.success(res.msg);
                this.$router.replace({ path: "/forum" })
              })
          } else {
            submit({...this.ruleForm}).then((res) => {
                this.ruleForm = {title: "",tags: [],type: "",content: "",}
                this.$message.success(res.msg)
                this.$router.replace({ path: "/forum" })
              })
          }
        }else if(errors.length > 0){
          this.$handleMessage(errors[0].message , 'error');
        }
      })
    },
  },
};
</script>
<style lang="less">
.ql-editor {
  img {
    display: inline-block;
    width: 100px;
    height: 100px;
  }
}
</style>

<style lang="less" scoped>
.border {
  border: 1px solid #bcbcbc;
}
.box {
  display: flex;
  justify-content: space-between;
  .tag_box{
    .el-tag-box .el-tag-item{
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }
  .content-left {
    width: 70%;
    p {
      margin: 1% 0 2%;
      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3f3f3f;
        line-height: 33px;
        margin-right: 3%;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      div {
        height: 30px;
        line-height: 30px;
        padding: 0 2.3%;
        background: #00957e;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .content-right {
    width: 27%;
  }
}
</style>