<!--  -->
<template>
  <div class="HotRecommend">
    <div class="title">
      <!-- <el-image
                :src="require('@/assets/images/problemList/热门@2x.png')"
                style="width: 24px; height: 24px"
              ></el-image> -->
      <div>推荐阅读</div>
    </div>
    <div
      class="HotRecommend-item"
      v-for="(v, i) in recommendList.slice(0,5)"
      :key="i"
      @click="clickRecommend(v)"
    >
      <el-image
        :src="require('@/assets/images/problemList/编组7@2x.png')"
        style="min-width: 24px; height: 24px;max-width: 24px;"
        class="image"
      ></el-image>
      <span class="text">{{ v.title }}</span>
    </div>
  </div>
</template>

<script>
import { recommend } from "@/api/forum.js";

export default {
  //import引入的组件需要注入到对象中才能使用

  components: {},
  data() {
    //这里存放数据
    return {
      path: "",
      recommendList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.path = this.$route.path;
    //获取推荐帖子阅读
    this.getRecommendList();
  },
  //方法集合
  methods: {
    // 推荐帖子阅读
    getRecommendList() {
      recommend({})
        .then(({ data }) => {
          this.recommendList = data.data;
          console.log(data, "推荐成功");
        })
        .catch((err) => {
          console.log(err, "推荐失败");
        });
    },
    clickRecommend(v) {
      // console.log(this.path);
      // if (this.path == "/forum/forumSee") {
      //   this.$parent.id = v.id;
      //   this.$parent.getForumDetalis();
      //   return
      // }
      this.$router.push({ path: "/forum/forumSee", query: { id: v.id } });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
.HotRecommend {
  background-color: #fff;
  padding: 1rem;
  .title {
    display: flex;
    font-size: 18px;
    color: #00957e;
    line-height: 25px;
    font-weight: 600;
    > div {
      margin-right: 0.3rem;
    }
  }
  .HotRecommend-item {
    padding-left: 2rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #363636;
    margin-top: 0.8rem;
    cursor: pointer;

    .text {
      margin-left: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>